@import "src/scss/module";

.nav_cont {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  z-index: -1;
  padding: 121px 24px 24px 24px;

  .button {
    display: inline-block;
    text-decoration: none;
    color: white;
    border: none;
    padding: 0;
    border-bottom: solid 1px transparent;
    transition: $default-transition;

    &:hover {
      border-bottom: solid 1px white;
    }
  }

  .dropDownMenu {
    max-height: 0;
    overflow: hidden;
    transition: 0.3s;

    @media (max-width: 576px) {
      margin-bottom: 1vh;
      margin-top: -1vh;
    }

    &:global(.open) {
      max-height: 120px;
    }

    .dropDownDesc {
      display: flex;
      text-align: center;
      color: #fff;
      font-weight: 400;
      font-size: 16px;
      padding: 8px 16px;
    }
  }

  .container {
    display: flex;
    justify-content: space-between;
    background-color: var(#{--bs-primary});
    border-radius: 24px;
    height: 100%;
    color: white;
    padding: 10vh 7vw;
    position: relative;

    .icon {
      object-fit: contain;
    }

    .pattern {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 0;
    }

    .left_cont {
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .icon {
        display: inline-block;
        padding-left: 10px;
        transition: transform 0.3s ease;
      }
      
      .icon.open {
        transform: rotate(45deg); /* Rotate the plus sign to form a down arrow */
      }

      .link {
        display: inline-block;
        text-decoration: none;
        color: white;
        border-bottom: solid 1px transparent;

        &:hover {
          border-bottom: solid 1px white;
          transition: $default-transition;
        }
      }
    }

    .right_cont {
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .top_cont {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 24px;
      }

      .bottom_cont {
        display: flex;
        gap: 5vw;
        justify-content: flex-end;

        .contact_cont {
          .contact_title {
            color: white;
          }

          .highlights {
            max-width: 273px;
            margin-top: 24px;

            .highlight {
              display: flex;
              gap: 12px;
              align-items: flex-start;

              svg {
                fill: white;
              }
            }
          }
        }

        .socials {
          border-radius: 32px;
          border: solid 1px white;
          padding: 16px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          .social_icon {
            display: block;

            svg {
              fill: white;
            }
          }
        }
      }
    }
  }

  .container {
    display: flex;
    justify-content: space-between;
    background-color: var(#{--bs-primary});
    border-radius: 24px;
    height: 100%;
    color: white;
    padding: 10vh 7vw;
    position: relative;

    .pattern {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 0;
    }

    .left_cont {
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .link {
        display: inline-block;
        text-decoration: none;
        color: white;
        border-bottom: solid 1px transparent;

        &:hover {
          border-bottom: solid 1px white;
          transition: $default-transition;
        }
      }
    }

    .right_cont {
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .top_cont {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 24px;
      }

      .bottom_cont {
        display: flex;
        gap: 5vw;
        justify-content: flex-end;

        .contact_cont {
          @media (max-width: 576px) {
            margin-right: -20px;
          }

          .contact_title {
            color: white;

            @media (max-width: 576px) {
              margin: 40px 20px 0 20px;
            }
          }

          .highlights {
            max-width: 273px;

            .highlight {
              display: flex;
              gap: 12px;
              align-items: flex-start;

              svg {
                fill: white;
              }
            }
          }
        }

        .socials {
          border-radius: 32px;
          border: solid 1px white;
          padding: 16px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          @media (max-width: 576px) {
            position: relative;
          }

          .social_icon {
            display: block;

            svg {
              fill: white;
            }
          }
        }
      }
    }
  }

  .socials {
    @media (max-width: 576px) {
      position: relative;
      top: 30px;
      left: 20px;
      margin-left: -4px;
    }
  }
}

@media (max-width: 992px) {
}

@media (max-width: 768px) {
  .nav_cont {
    padding: 121px 8px 8px 8px;

    .container {
      padding: 5vh 3vw;
      gap: 5vw;

      .left_cont {
        position: relative;
        z-index: 1;

        .link,
        .button {
          margin-bottom: 2vh;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .nav_cont {
    .container {
      flex-direction: column;
      overflow-y: auto;

      .left_cont {
        position: relative;
        margin-bottom: 5vh;

        .link {
          text-align: center;
        }
      }

      .right_cont {
        padding: 0 24px;

        .top_cont {
          display: none;
        }
      }
    }
  }
}

